<template>
    <section class="page-container">
        <div class="server-error"></div>
    </section>
</template>

<style lang="less" scoped>
.page-container {
    padding: 10%;
    .server-error {
        height: 480px;
        background: url(../../assets/img/server_error.png) no-repeat center;
    }
}
</style>
